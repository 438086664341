<template>
  <div class="signup-template">
    <section class="signup-template__progress">
      <ev-progress
        :value="progressAmount"
        color="red"
        data-test="progress-bar"
      />
    </section>

    <section class="signup-template__item--padded">
      <div class="signup-template__container">
        <img
          alt="Revelo logo"
          data-test="revelo-logo"
          class="signup-template__logo"
          src="@/assets/images/logo-revelo-small-alt.png"
        >
        <slot name="text" />
      </div>
    </section>

    <section class="signup-template__item">
      <slot name="form" />
    </section>
  </div>
</template>

<script>
import EvProgress from '@revelotech/everestV2/EvProgress'

export default {
  name: 'SignupTemplate',
  components: {
    EvProgress
  },
  props: {
    progressAmount: {
      type: Number,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.signup-template {
  align-content: stretch;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;

  &__item--padded {
    background-color: var(--bg-blue);
    padding: $base*10;
  }

  &__item {
    padding: $base*10;
  }

  &__logo {
    @include margin(bottom, 4);

    width: $base*16;
  }

  @include breakpoint(md) {
    display: grid;
    grid-template-columns: 1fr 1fr;

    &__progress {
      grid-column: 1 / span 2;
      left: 0;
      position: fixed;
      top: 0;
      width: 100%;
    }

    &__container {
      position: relative;
    }

    &__logo {
      @include margin(bottom, 24);

      position: absolute;
      top: -$base*16*2;
    }

    &__item {
      padding: 0;
    }

    &__item--padded {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: $base*24;
    }
  }
}
</style>
